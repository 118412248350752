import * as client_hooks from '../../../src/app/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(app)": [6,[2],[3]],
		"/(app)/artists": [8,[2],[3]],
		"/(app)/artists/create": [11,[2],[3]],
		"/(app)/artists/[id]": [9,[2],[3]],
		"/(app)/artists/[id]/edit": [10,[2],[3]],
		"/(app)/artworks/create": [14,[2],[3]],
		"/(app)/artworks/[id]": [12,[2],[3]],
		"/(app)/artworks/[id]/edit": [13,[2],[3]],
		"/(app)/auctions": [15,[2],[3]],
		"/(app)/auctions/create": [18,[2],[3]],
		"/(app)/auctions/[id]": [16,[2],[3]],
		"/(app)/auctions/[id]/edit": [17,[2],[3]],
		"/(auth)/change-email": [31,[5]],
		"/(auth)/change-password": [32,[5]],
		"/(auth)/check-email": [~33,[5]],
		"/(auth)/confirm-email/[token]": [~34,[5]],
		"/(app)/documents": [19,[2],[3]],
		"/(app)/documents/create": [22,[2],[3]],
		"/(app)/documents/[id]": [20,[2],[3]],
		"/(app)/documents/[id]/edit": [21,[2],[3]],
		"/(app)/notifications": [23,[2],[3]],
		"/(app)/profile/gallery/employees": [24,[2,4],[3]],
		"/(app)/profile/gallery/user-settings": [25,[2,4],[3]],
		"/(auth)/request-reset-password": [~35,[5]],
		"/(app)/requests": [26,[2],[3]],
		"/(app)/requests/[id]": [27,[2],[3]],
		"/(auth)/reset-password": [~36,[5]],
		"/(auth)/sign-in": [~37,[5]],
		"/(auth)/sign-out": [38,[5]],
		"/(auth)/sign-up": [~39,[5]],
		"/(app)/store-configurations": [28,[2],[3]],
		"/(app)/store-configurations/edit": [29,[2],[3]],
		"/(app)/transactions": [30,[2],[3]],
		"/(app)/[...unexpected]": [7,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';